import './reset.css'

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom"
import './index.css';

import App from '../src/components/App/App';
import * as serviceWorker from './serviceWorker';

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat' // load on demand

import { history } from './history'

dayjs.extend(advancedFormat) // use plugin


ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
